import React, { Component } from "react";
import TagManager from "react-gtm-module";
import Header from "./Header";
import Card from "./Card";
import { demos } from "../lib/demos";
import { v4 as uuidv4 } from "uuid";
import "../sass/App.scss";

const tagManagerArgs = {
  gtmId: "GTM-5KJ7CXS",
};
TagManager.initialize(tagManagerArgs);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <main className="App">
        <Header />
        <h1 className="App__title">Appsilon R Shiny Demo Gallery</h1>
        <h3 className="App__welcome">A curated collection of unique <a href="https://appsilon.com/" target="_blank" rel="noopener noreferrer">R Shiny dashboard examples</a>. Explore the possibilities of R Shiny.</h3>
        {/* Grid of demo cards */}
        <div className="App__grid">
          {demos.map((demo) => {
            const { effort, title, shortDescription, demoUrl, video, poster } = demo;
            return (
              <Card
                key={uuidv4()}
                effort={effort}
                title={title}
                shortDescription={shortDescription}
                demoUrl={demoUrl}
                video={video}
                poster={poster}
              />
            );
          })}
        </div>
      </main>
    );
  }
}

export default App;
