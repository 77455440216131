import React, { Component } from "react";
import vectors from "../assets/img/vectors.svg";
import logo from "../assets/img/logo_appsilon_color.svg";
import classNames from "classnames";
import "../sass/Header.scss";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuVisible: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuVisible: !prevState.isMobileMenuVisible,
    }));
  };

  render() {
    const { isMobileMenuVisible } = this.state;

    const menuClass = classNames("Header__menu", {
      "Header__menu--visible": isMobileMenuVisible,
    });

    return (
      <header className="Header">
        <h1 className="Header__title visuallyhidden">
          Appsilon Data Science - Demos
        </h1>

        <div className="Header__wrapper">
          {/* Appsilon Logo */}
          <a
            className="Header__logo"
            href="https://appsilon.com/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Appsilon Data Science Website"
          >
            <img
              className="Header__svg"
              src={logo}
              alt="Appsilon Data Science Logo"
            ></img>
          </a>

          {/* Menu */}
          <nav className={menuClass}>
            <a
              className="Header__link"
              href="https://appsilon.com/#contact"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Schedule Appsilon Shiny Demo"
            >
              Let's Talk
            </a>
          </nav>

          {/* Burger Button */}
          <button className="Header__burger" onClick={this.toggleMobileMenu}>
            <svg className="Header__svg" viewBox="0 0 100 100">
              <use href={`${vectors}#burger`}></use>
            </svg>
          </button>
        </div>
      </header>
    );
  }
}

export default Header;
