import React, { Component } from 'react';
import HtmlParser from 'html-react-parser';
import '../sass/Card.scss';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDemoVisible: false,
    };
  }

  handleMouseEnter = (e) => {
    e.target.lastElementChild.setAttribute('autoplay', true);
    e.target.lastElementChild.play();
  };

  handleMouseLeave = (e) => {
    e.target.lastElementChild.pause();
    e.target.lastElementChild.currentTime = 0;
  };

  render() {
    const { effort, title, shortDescription, demoUrl, video, poster } = this.props;

    return (
      <div className='Card'>
        <div className='Card__wrapper'>
          <a href={demoUrl} target='_blank' rel='noopener noreferrer'>
            <div
              className='Card__preview'
              onMouseEnter={(e) => this.handleMouseEnter(e)}
              onMouseLeave={(e) => this.handleMouseLeave(e)}
            >
              <video className='Card__image' poster={poster} loop preload="none" muted="muted">
                <source src={video} type='video/mp4'></source>
              </video>
            </div>
            <div className='Card__header'>
              <h6 className='Card__effort'>EFFORT: {effort}</h6>
              <h2 className='Card__title'>{title}</h2>
              <p className='Card__description'>
                {HtmlParser(shortDescription)}
              </p>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Card;
