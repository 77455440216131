import sankey_chart_example from '../assets/video/Sankey_Chart_Recording_App.mp4';
import sankey_chart_example_poster from '../assets/img/sankey_chart_example.jpg';
import forest from '../assets/video/Future Forests.mp4';
import forest_poster from '../assets/img/forest-ranges.png';
import racial_diversity_in_us from '../assets/video/Racial_Diversity_App_recording.mp4';
import racial_diversity_in_us_poster from '../assets/img/racial_diversity_in_us.jpg';
import respiratory from '../assets/video/Respiratory_Disease_App_recording.mp4';
import respiratory_poster from '../assets/img/respiratory-disease.png';
import bee_colony from '../assets/video/Bee Colony Losses.mp4';
import bee_colony_poster from '../assets/img/bee_colony.jpg';
import building_damage_assessment from '../assets/video/ML Building Damage Assesment.mp4';
import building_damage_assessment_poster from '../assets/img/building_damage_assessment.jpg';
import visuarisk from '../assets/video/Visualise.mp4';
import visuarisk_poster from '../assets/img/visuarisk.jpg';
import source_electricity from '../assets/video/Source of Electricity.mp4';
import source_electricity_poster from '../assets/img/source_electricity.png';
import arctic_fauna from '../assets/video/Arctic_Fauna_App_Recording.mp4';
import arctic_fauna_poster from '../assets/img/arctic_fauna.jpg';
import europeana_artist_app from '../assets/video/Europeana.mp4';
import europeana_artist_app_poster from '../assets/img/europeana_artist_app.jpg';
import polluter_alert from '../assets/video/Polluter_Alert_App_recording.mp4';
import polluter_alert_poster from '../assets/img/polluter_alert.jpg';
import olympic_medals from '../assets/video/Olympics_App_recording.mp4';
import olympic_medals_poster from '../assets/img/olympic_medals.jpg';
import appsidex from '../assets/video/Appsidex_app_Recording.mp4';
import appsidex_poster from '../assets/img/appsidex.png';
import drawflow from '../assets/video/Drawflow_App_recording.mp4';
import drawflow_poster from '../assets/img/drawflow.jpeg';
import jhu_lyme from '../assets/video/Johns Hopkins University Lyme Dashboard.mp4';
import jhu_lyme_poster from '../assets/img/jhu_lyme_tracker.png';
import flights_fraud from '../assets/video/Flights Fraud Demo.mp4';
import flights_fraud_poster from '../assets/img/flights_fraud.jpg';
import semantic_pixelator from '../assets/video/Semantic Pixelator.mp4';
import semantic_pixelator_poster from '../assets/img/semantic_pixelator.jpg';
import earthquakes from '../assets/video/Quake Explorer.mp4';
import earthquakes_poster from '../assets/img/earthquakes.png';
import shiny_fluent from '../assets/video/Shiny Dashboard Built With Fluent UI.mp4';
import shiny_fluent_poster from '../assets/img/shiny_fluent.jpg';
import shiny_enterprise from '../assets/video/Shiny Enterprise Dashboard.mp4';
import shiny_enterprise_poster from '../assets/img/shiny_enterprise.jpg';
import lake_profile from '../assets/video/Lake_Profile_App_recording.mp4';
import lake_profile_poster from '../assets/img/lake_profile.jpg';
import shiny_blueprint from '../assets/video/Shiny_Blueprint_App_Recording.mp4';
import shiny_blueprint_poster from '../assets/img/shiny_blueprint.png';
import rhino_fda_pilot from '../assets/video/rhino_fda_pilot.mp4';
import rhino_fda_pilot_poster from '../assets/img/rhino_fda_pilot.png';

export const demos = [
  {
    title: 'Shiny Enterprise Dashboard',
    effort: '2 WEEKS',
    shortDescription: `The apps are secure, dynamic, and custom-featured. This is an example of an enterprise Shiny dashboard with custom modern UI.`,
    demoUrl: 'https://connect.appsilon.com/shiny-enterprise-demo/',
    video: shiny_enterprise,
    poster: shiny_enterprise_poster,
  },
  {
    title: 'Future Forests',
    effort: 'LONG-TERM PROJECT',
    shortDescription: `Data for Good (D4G) initiative, we collaborated with the Institute of Dendrology of the Polish Academy of Sciences, and built a data visualization app to show how different climate scenarios will affect European forests.`,
    demoUrl: 'https://connect.appsilon.com/future-forests/',
    video: forest,
    poster: forest_poster,
  },
  {
    title: 'Racial Diversity in US',
    effort: '3 DAYS',
    shortDescription: `Simple dashboard providing information about racial diversity in the USA.`,
    demoUrl: 'https://connect.appsilon.com/racial-diversity-in-us/',
    video: racial_diversity_in_us,
    poster: racial_diversity_in_us_poster,
  },
  {
    title: 'Respiratory Disease App',
    effort: '15 days',
    shortDescription: `Data for Good (D4G) initiative, we wanted to create an app that better visualized the correlation between human mortality rates and a common cause on a global scale.`,
    demoUrl: 'https://connect.appsilon.com/respiratory_disease_app_sprint/',
    video: respiratory,
    poster: respiratory_poster,
  },
  {
    title: 'Bee Colony Losses',
    effort: '1 DAY',
    shortDescription: `Sample usage of Tailwind CSS in Shiny application.`,
    demoUrl: 'https://connect.appsilon.com/bee-colony/',
    video: bee_colony,
    poster: bee_colony_poster,
  },
  {
    title: 'ML Building Damage Assessment',
    effort: '3 DAYS',
    shortDescription: `The intuitive user interface allows the user to explore the impact of real-world natural disasters, given a pair of images before and after the disaster, the model locates buildings and assesses the damage they sustained.`,
    demoUrl: 'https://connect.appsilon.com/ai-for-disaster-recovery/',
    video: building_damage_assessment,
    poster: building_damage_assessment_poster,
  },
  {
    title: 'Visualise',
    effort: '3 WEEKS',
    shortDescription: `By combining spatially explicit hazard modeling with a nationwide household survey data, Visualise allows for the consistent analysis of cyclone risk, helping users visualize who's at risk in Madagascar.`,
    demoUrl: 'https://connect.appsilon.com/visualise/',
    video: visuarisk,
    poster: visuarisk_poster,
  },
  {
    title: 'Source of Electricity',
    effort: '4 DAYS',
    shortDescription: `A dashboard that allows the exploration of electricity production and its change over time. The application is built using the rhino package.`,
    demoUrl: 'https://connect.appsilon.com/source_electricity_master/',
    video: source_electricity,
    poster: source_electricity_poster,
  },
  {
    title: 'Arctic Fauna',
    effort: '4 DAYS',
    shortDescription: `A dashboard to explore observation trends and location of diverse artic species.`,
    demoUrl: 'https://connect.appsilon.com/arctic-fauna/',
    video: arctic_fauna,
    poster: arctic_fauna_poster,
  },
  {
    title: 'Europeana Artist Dashboard',
    effort: '5 DAYS',
    shortDescription: `Application that uses an Europeana API to search for an artist and present the location of their art pieces in a visual dashboard.`,
    demoUrl: 'https://connect.appsilon.com/europeana-artist-app/',
    video: europeana_artist_app,
    poster: europeana_artist_app_poster,
  },
  {
    title: 'Polluter Alert',
    effort: '3 DAYS',
    shortDescription: `Polluter Alert is a dashboard that allows the user to report sources of air pollution in the user's area.`,
    demoUrl: 'https://connect.appsilon.com/polluter-alert/',
    video: polluter_alert,
    poster: polluter_alert_poster,
  },
  {
    title: 'Olympic Medals History',
    effort: '5 DAYS',
    shortDescription: `This app is based on shiny-template. It is meant to be a showcase of the template features.`,
    demoUrl: 'https://connect.appsilon.com/olympic_history_map/',
    video: olympic_medals,
    poster: olympic_medals_poster,
  },
  {
    title: 'Appsidex',
    effort: '3 weeks',
    shortDescription: `Appsidex stands for 'Appsilon' + 'Pokedex'. This app lets users learn more about their colleagues from their location to their personal background and hobbies. From a technical perspective, the app serves as a public demo for the <a href='https://appsilon.github.io/shiny.router/' target='_blank' rel='noopener noreferrer'>shiny.router</a> package.`,
    demoUrl: 'https://connect.appsilon.com/appsidex/',
    video: appsidex,
    poster: appsidex_poster,
  },
  {
    title: 'Drawflow',
    effort: '8 DAYS',
    shortDescription: `This is a sample implementation of Drawflow (JS) in Shiny.`,
    demoUrl: 'https://connect.appsilon.com/drawflow',
    video: drawflow,
    poster: drawflow_poster,
  },
  {
    title: 'Johns Hopkins University Lyme Dashboard',
    effort: 'LONG-TERM PROJECT',
    shortDescription: `Dashboard for the Hopkins Lyme and Tick-borne Disease Initiative.`,
    demoUrl: 'https://www.hopkinslymetracker.org/explorer',
    video: jhu_lyme,
    poster: jhu_lyme_poster,
  },
  {
    title: 'Flights Fraud Demo',
    effort: '1 DAY',
    shortDescription: `This Manual Review app proposed by Appsilon works as a part of a hybrid, human-computer decision-making system for flight fraud detection assisted by tables, maps, and diagrams created by Shiny.`,
    demoUrl: 'https://connect.appsilon.com/flights_fraud_demo/',
    video: flights_fraud,
    poster: flights_fraud_poster,
  },
  {
    title: 'Semantic Pixelator',
    effort: '1 DAY',
    shortDescription: `Semantic pixelator is a fun way to explore semantic elements by creating different image compositions using loaders, icons, and other UI elements from semantic/fomantic UI.`,
    demoUrl: 'https://connect.appsilon.com/pixelator/',
    video: semantic_pixelator,
    poster: semantic_pixelator_poster,
  },
  {
    title: 'Quake Explorer',
    effort: '2 DAYS',
    shortDescription: `Application to explore quakes occurred during may 2022. An implementation of shiny.fluent in combination with imola.`,
    demoUrl: 'https://connect.appsilon.com/quake_explorer/',
    video: earthquakes,
    poster: earthquakes_poster,
  },
  {
    title: 'Shiny Dashboard Built with Fluent UI',
    effort: '2 DAYS',
    shortDescription: `A Shiny dashboard leveraging Fluent UI, which is a powerful library from Microsoft combining an impressive look with a professional feel.`,
    demoUrl: 'https://connect.appsilon.com/fluentui/',
    video: shiny_fluent,
    poster: shiny_fluent_poster,
  },
  {
    title: 'Interactive Sankey Chart',
    effort: '2 WEEKS',
    shortDescription: `Interactive sankey chart for visualizing data.`,
    demoUrl: 'https://connect.appsilon.com/sankey_chart_example/',
    video: sankey_chart_example,
    poster: sankey_chart_example_poster,
  },
  {
    title: 'Lake Profile App',
    effort: '5 DAYS',
    shortDescription: `This application is designed to interactively visualize lake profile data collected by DWQ and collaborators.`,
    demoUrl: 'https://connect.appsilon.com/lake-profile-app/',
    video: lake_profile,
    poster: lake_profile_poster,
  },
  {
    title: 'Shiny Blueprint',
    effort: '3 WEEKS',
    shortDescription: `A Shiny dashboard showcasing <a href='https://github.com/Appsilon/shiny.blueprint' target='_blank' rel='noopener noreferrer'>shiny.blueprint</a> - Appsilon's open source package porting Palantir's React-based UI toolkit, Blueprint.`,
    demoUrl: 'https://connect.appsilon.com/shiny-blueprint-showcase/',
    video: shiny_blueprint,
    poster: shiny_blueprint_poster,
  },
  {
    title: 'FDA Pilot App - Rewrite to Rhino',
    effort: '2 WEEKS',
    shortDescription: `A pilot app created to pave the way for R-based clinical trial regulatory submissions to FDA. Initially developed by R Submission Working Group, rewritten to use the Rhino framework by Appsilon.`,
    demoUrl: 'https://connect.appsilon.com/rhino-fda-pilot/',
    video: rhino_fda_pilot,
    poster: rhino_fda_pilot_poster,
  },
];
